<template>
    <div>
        <v-row >
            <v-col cols="auto">
          <div class="play-back " >
            <hb-btn
            icon
            active-by-default
            always-active
            active-background-color="#637381"
            color="#ffffff"
            active-color="#ffffff"
            hover-color="#ffffff"
            hover-opacity
            @click="toggleSpeech(text)"
            right
            :tooltip="checkPausePlay"
            :disabled="!text.length"
          >
            mdi-play-pause
          </hb-btn>
          <div class="playback-info pt-4">
            <strong>Preview</strong>
            <p style="margin-top: 0%;"> <strong>0:{{ timeRemaining }}</strong>/0:{{getTotalTime(text)}}</p>
          </div>
        </div>
      </v-col>
            
      <v-col>
        <p  style="color: #637381;" >*Notice: The preview text-to-speech voice differs from the voice your callers will hear when they call. Ensure quality by placing a real phone call to test your Auto-Attendant menu.</p>
      </v-col>
    </v-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
      checkPausePlay: "Play",
      isPlaying: false,
      timeRemaining: 0,
      speechUtterance: null,
      speechSynthesis: window.speechSynthesis,
      timer: null,
      totalTime: 0,
            }
        },
        props: {
            text: {
                type: String,
                default: ""
            },
            selectedVoice:{
                type:String,
                default:"1"
            }
         
        },
        methods: {
          //speech to text 
toggleSpeech(message) {
      if (!this.isPlaying) {
        this.startSpeech(message);
        this.checkPausePlay = "Pause";
      } else {
        this.pauseSpeech();
        this.checkPausePlay = "Play";
      }
    },
    startSpeech(message) {
      this.isPlaying = true;
      this.timeRemaining = this.calculateDuration(message) == 1 ? this.calculateDuration(message) + 1 : this.calculateDuration(message);
      this.speechUtterance = new SpeechSynthesisUtterance(message);
      this.speechUtterance.voice = this.getVoice();
      this.speechSynthesis.speak(this.speechUtterance);
      this.timer = setInterval(() => {
        this.timeRemaining--;
        if (this.timeRemaining <= 0) {
          this.pauseSpeech();
        }
      }, 1000);
    },
    pauseSpeech() {
      this.isPlaying = false;
      clearInterval(this.timer);
      this.speechSynthesis.cancel();
      this.timeRemaining = 0;
      this.checkPausePlay = "Play";
    },
    getVoice() {
      const voices = this.speechSynthesis.getVoices();
      let voice1;
      if (this.selectedVoice == '1') {
        voice1 = 'Microsoft David - English (United States)';
      } else {
        voice1 = 'Microsoft Zira - English (United States)';
      }
      return voices.find(voice => voice.name.includes(voice1));
    },
    calculateDuration(text) {
      if (text.trim().length == 0) {
        return 0;
      } else {
        const words = text.trim().split(/\s+/).length;
        const durationInMinutes = words / 150;
        return Math.ceil(durationInMinutes * 60);
      }
    },

   getTotalTime(message){   
        return this.calculateDuration(message) == 1 ? this.calculateDuration(message) + 1 : this.calculateDuration(message);      
   },
        },
    }
</script>

<style lang="scss" scoped>
  .play-back{
    width: 125px;
    height: 60px;
    background: #F4F6F8;
    display: flex;
    align-items: center; 
    gap: 10px;
    padding: 10px;
    border-radius:4px;
  }
  .playback-info {
    display: inline-block;
  }
</style>